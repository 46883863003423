import { z, type ZodAny, type ZodTypeAny } from "zod";
import { locales, type Locale } from "@mono/constants/lib/locale";
import { generalReceiptEnum } from "@mono/constants/lib/generalReceipt";
import { adminRoles } from "@mono/constants/lib/adminAccessRules";
export { generalReceiptEnum, type Locale };
export type ZodSchemaType<T> = {
  [key in keyof T]: ZodTypeAny;
};

// main validation for dates
// strict iso date format, without time
export const dateStringSchema = z.string().refine(
  (val) => {
    return (
      z
        .string()
        .regex(
          /^2[0-1]\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])(T00:00:00.000Z)?$/
        )
        .safeParse(val).success && z.coerce.date().safeParse(val).success
    );
  },
  {
    params: { i18n: "custom.invalid_date_string" },
  }
);

export const jsonSchema = z.union([
  z.record(z.string().min(1), z.any()),
  z.array(z.record(z.string().min(1), z.any())),
]);

export type JsonSchema = z.infer<ZodAny>;

export const nonEmptyStringSchema = z.string().trim().min(1);
export const emptyStringSchema = z.string().min(0).max(0);

export const stringNumbersSchemaRefinement = (
  arg: string,
  ctx: z.RefinementCtx
) => {
  if (new RegExp(/^(|\d+)$/).test(arg) === false) {
    ctx.addIssue({
      code: "custom",
      params: { i18n: "custom.invalid_number_string" },
    });
  }
};

export const stringNumbersSchema = z.string().trim();

export const passwordSchemaRefinement = (arg: string, ctx: z.RefinementCtx) => {
  if (new RegExp(/^[^'";\s]*$/).test(arg) === false) {
    ctx.addIssue({
      code: "custom",
      params: { i18n: "custom.invalid_alpha" },
    });
  }
};

export const passwordSchema = z.string().min(6).max(128);

export const taxNumberSchema = stringNumbersSchema
  .min(1)
  .length(15)
  .superRefine(stringNumbersSchemaRefinement)
  .or(emptyStringSchema);

export const phoneNumberSchemaRefinement = (
  arg: string,
  ctx: z.RefinementCtx
) => {
  if (new RegExp(/^([0-9+]{7,16}|)$/).test(arg) === false) {
    ctx.addIssue({
      code: "custom",
      params: { i18n: "custom.invalid_phone_number" },
    });
  }
};

export const phoneNumberSchema = z.string();

export const phoneCCSchemaRefinement = (arg: string, ctx: z.RefinementCtx) => {
  if (new RegExp(/^\+[0-9]{1,4}$/).test(arg) === false) {
    ctx.addIssue({
      code: "custom",
      params: { i18n: "custom.invalid_phone_cc" },
    });
  }
};

export const phoneCCSchema = z.string();

export const promoCodeSchemaRefinement = (
  arg: string,
  ctx: z.RefinementCtx
) => {
  if (new RegExp(/^[A-Za-z0-9-]+$/).test(arg) === false) {
    ctx.addIssue({
      code: "custom",
      params: { i18n: "custom.invalid_promo_code" },
    });
  }
};

export const promoCodeSchema = z.string().min(2).max(15);

export const bilingualTextSchema = nonEmptyStringSchema
  .regex(/^[^><}{\[\]]*$/)
  .max(1000);

export const translationSchema = z
  .object({
    en: bilingualTextSchema.or(emptyStringSchema).optional(),
    ar: bilingualTextSchema.or(emptyStringSchema).optional(),
  })
  .optional();

export type TranslationSchema = z.infer<typeof translationSchema>;

export const localeSchema = z
  .string()
  .refine((val) => locales.includes(val as Locale), {
    message: `Must be one of ${locales.join(", ")}`,
    params: {
      i18n: {
        key: "errors.invalid_literal",
        expected: { literal: locales.join(", ") },
      },
    },
  });

export const FILE_SIZE_LIMIT = 10485760; // 10MB

// comparisons schemas
export const dateComparisonExpSchema = z.object({
  _eq: dateStringSchema.nullish(),
  _gt: dateStringSchema.nullish(),
  _gte: dateStringSchema.nullish(),
  _lt: dateStringSchema.nullish(),
  _lte: dateStringSchema.nullish(),
  _is_null: z.boolean().nullish(),
});

export type DateComparisonExpSchema = z.infer<typeof dateComparisonExpSchema>;

export const timestamptzComparisonExpSchema = z.object({
  _eq: z.string().datetime().nullish(),
  _gt: z.string().datetime().nullish(),
  _gte: z.string().datetime().nullish(),
  _lt: z.string().datetime().nullish(),
  _lte: z.string().datetime().nullish(),
  _is_null: z.boolean().nullish(),
});

export type TimestamptzComparisonExpSchema = z.infer<
  typeof timestamptzComparisonExpSchema
>;

export const uuidComparisonExpSchema = z.object({
  _eq: z.string().uuid().nullish(),
  // _gt: z.string().uuid(),
  // _gte: z.string().uuid(),
  // _lte: z.string().uuid(),
  _is_null: z.boolean().nullish(),
  _in: z.array(z.string().uuid()).nullish(),
});

export type UuidComparisonExpSchema = z.infer<typeof uuidComparisonExpSchema>;

export const booleanComparisonExpSchema = z.object({
  _eq: z.boolean().nullish(),
  // _gt: z.boolean(),
  // _gte: z.boolean(),
  // _lt: z.boolean(),
  // _lte: z.boolean(),
  _is_null: z.boolean().nullish(),
});

export type BooleanComparisonExpSchema = z.infer<
  typeof booleanComparisonExpSchema
>;

export const stringComparisonExpSchema = z.object({
  _ilike: z.string().nullish(),
  _eq: z.string().nullish(),
  _neq: z.string().nullish(),
  _in: z.array(z.string()).nullish(),
});

export type StringComparisonExpSchema = z.infer<
  typeof stringComparisonExpSchema
>;

export const numericComparisonExpSchema = z.object({
  _eq: z.number().nullish(),
  _gt: z.number().nullish(),
  _gte: z.number().nullish(),
  _lt: z.number().nullish(),
  _lte: z.number().nullish(),
});

export type NumericComparisonExpSchema = z.infer<
  typeof numericComparisonExpSchema
>;

export const intComparisonExpSchema = z.object({
  _eq: z.number().int().nullish(),
  _gt: z.number().int().nullish(),
  _gte: z.number().int().nullish(),
  _lt: z.number().int().nullish(),
  _lte: z.number().int().nullish(),
});

export type IntComparisonExpSchema = z.infer<typeof intComparisonExpSchema>;

export const generalReceiptTypeComparisonExpSchema = z.object({
  _eq: z.nativeEnum(generalReceiptEnum).nullish(),
});

export type GeneralReceiptTypeComparisonExpSchema = z.infer<
  typeof generalReceiptTypeComparisonExpSchema
>;

export enum OrderByEnum {
  asc = "asc",
  asc_nulls_first = "asc_nulls_first",
  asc_nulls_last = "asc_nulls_last",
  desc = "desc",
  desc_nulls_first = "desc_nulls_first",
  desc_nulls_last = "desc_nulls_last",
}

export type OrderByType = keyof typeof OrderByEnum;

export const orderBySchema = z.enum([
  "asc",
  "asc_nulls_first",
  "asc_nulls_last",
  "desc",
  "desc_nulls_first",
  "desc_nulls_last",
]);

export const printNotesSchema = z.array(
  z.object({
    title: z.string(),
    text: z.string(),
  })
);

export type PrintNotesSchema = z.infer<typeof printNotesSchema>;

export const transactionSource = [
  "manualJournals",
  "generalReceipts",
  "purchasingInvoices",
  "purchasingReceipts",
  "inventoryConsumptions",
  "inventoryTransfers",
  "inventoryStocktakes",
  "salesInvoices",
  "salesReceipts",
  "fixedAssets",
  "fixedAssetTransactions",
  "creditNotes",
  "debitNotes",
  "customersOpeningBalances",
  "vendorsOpeningBalances",
] as const;

export const transactionSourceSchema = z.enum(transactionSource);

export type TransactionSourceSchema = z.infer<typeof transactionSourceSchema>;

export const roundingNumberSchema = z
  .number()
  .transform((val) => Math.round(val));

export const adminRoleSchema = z.enum(adminRoles);

export type AdminRoleSchema = z.infer<typeof adminRoleSchema>;
